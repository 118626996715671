<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end pa-3">
          <v-btn small @click="$emit('close')" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 200px"
        >
          <div style="width: 90%">
            <p class="small_txt color_txt">Upload Bukti pembayaran</p>
            <v-file-input
              color="#3b053c"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Klik disini"
              dense
              v-model="file"
            ></v-file-input>
            <div class="pa-3">
              <v-btn dark color="#3b053c" @click="onUpdload" depressed block>
                Kirim
              </v-btn>
            </div>
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="purple"
            ></v-progress-circular>
            <b class="purple--text">Loading...</b>
          </v-overlay>
        </div>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "alertQuiz",
  props: ["dialogAlert"],

  data() {
    return {
      file: null,
      loading: false
    };
  },
  mounted() {
    console.log(this.$route.params.id);
  },
  methods: {
    onUpdload() {
      this.loading = true;
      let data = new FormData();
      data.append("id", this.$route.params.id);
      data.append("proof", this.file);
      this.$store
        .dispatch("counseling/evidence", data)
        .then(data => {
          console.log(data);
          this.Swal("success", "Success");
          this.loading = false;
          this.$emit("close");
          this.$emit("refetch");
        })
        .catch(err => {
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family"
        }
      });
    }
  }
};
</script>
