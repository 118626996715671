<template>
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top"></div>
    <v-card
      class="radius-card d-flex justify-center pa-3"
      style="box-sizing: border-box"
      min-height="500px"
      flat
    >
      <v-card outlined class="rounded-lg pa-5" width="65%" v-if="dataTrx">
        <div>
          <img src="@/assets/img/logopam.png" alt="" />
          <h3>Pembayaran Konseling</h3>
        </div>
        <div v-if="!dataTrx.proof">
          <v-alert
            dense
            border="left"
            type="warning"
            v-if="dataTrx.transactions_expired == 1"
          >
            Transaksi ini kadaluarsa
          </v-alert>
          <v-alert dense border="left" type="warning" v-else>
            Pembayaran akan kadaluarsa pada tanggal : <br />
            <p class="font-weight-bold mb-0">
              {{
                $date(dataTrx.expired_at)
                  .locale("id")
                  .format("dddd, DD MMMM YYYY  HH:mm")
              }}
            </p>
          </v-alert>
        </div>
        <div class="d-flex align-center mb-3">
          <v-icon color="#3b053c" class="mr-3">mdi-cart</v-icon>
          <p class="ma-0 font-weight-bold">Informasi Pesanan</p>
        </div>
        <v-card class="rounded-lg pa-3 mb-3" v-if="paket">
          <h3>{{ paket.nama }}</h3>
          <v-row no-gutters>
            <v-col cols="6">
              <p class="mb-0 small_txt">Durasi</p>
              <p class="mb-0 font-weight-bold">{{ paket.durasi }} menit</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 small_txt">Sesi</p>
              <p class="mb-0 font-weight-bold">
                {{ paket.sesi ? paket.sesi : 1 }}X Pertemuan
              </p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 small_txt">Invoice</p>
              <p class="mb-0 font-weight-bold">{{ dataTrx.id }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 small_txt">Harga</p>
              <p
                class="mb-0 text--grey small_txt"
                style="text-decoration: line-through"
              >
                Rp{{
                  paket.harga.slice(0, -3).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </p>
              <p class="mb-0 font-weight-bold">
                Rp{{ paket.harga_diskon.replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="rounded-lg pa-3">
          <div class="d-flex align-center">
            <v-avatar size="70">
              <img
                :src="
                  `${env}/upload/photo_profile/${dataTrx.psycholog.id}/${dataTrx.psycholog.photo_profile}`
                "
                alt="abir"
              />
            </v-avatar>
            <div class="ml-3">
              <p class="mb-0">Psikolog</p>
              <h3>{{ dataTrx.psycholog.nama_lengkap }}</h3>
              <p class="mb-0">
                Konseling
                {{
                  dataTrx.psycholog.bidang_id == 1
                    ? "Psikologi Klinis"
                    : dataTrx.psycholog.bidang_id == 2
                    ? "Psikologi Pendidikan"
                    : "Psikologi industri dan organisasi"
                }}
              </p>
            </div>
          </div>
        </v-card>
        <div class="my-3">
          <v-divider></v-divider>
        </div>
        <div class="d-flex align-center">
          <v-icon color="#3b053c" class="mr-3">mdi-wallet</v-icon>
          <p class="ma-0 font-weight-bold">Metode pembayaran</p>
        </div>
        <p class="small_txt">
          Silakan lakukan pembayaranke salah satu metode pembayaran berikut.
        </p>

        <v-card class="pa-3 rounded-lg mb-3" v-if="rek.length">
          <div class="mb-3" v-for="(item, i) in rek" :key="`rek${i}`">
            <div class="d-flex align-center">
              <v-icon>mdi-share</v-icon>
              <p class="mb-0 ml-3">{{ item.nama_layanan }}</p>
            </div>
            <div class="d-flex align-center">
              <p class="font-weight-bold mb-0 mr-3">
                {{ item.no_rek }}
              </p>
              <v-btn icon color="#3b053c" @click="copyRek(item.no_rek)">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </div>
            <p class="font-weight-bold mb-0 small_txt">
              A/N : {{ item.atasnama }}
            </p>
          </div>
        </v-card>
        <div class="d-flex justify-space-between">
          <div class="d-flex align-center">
            <v-icon color="#3b053c" class="mr-3">mdi-tag</v-icon>
            <p class="ma-0 font-weight-bold">Harga</p>
          </div>
          <div v-if="paket">
            <p
              class="mb-0 text--grey small_txt"
              style="text-decoration: line-through"
            >
              Rp{{
                paket.harga.slice(0, -3).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }}
            </p>
            <h3 class="mb-0 font-weight-bold">
              Rp{{ paket.harga_diskon.replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
            </h3>
          </div>
        </div>
        <div class="pa-3" v-if="role == 4">
          <div>
            <v-btn
              dark
              color="#3b053c"
              @click="dialogAlert = true"
              depressed
              block
              v-if="!dataTrx.proof"
            >
              Kirim Bukti Transfer
            </v-btn>
            <div v-if="dataTrx.proof">
              <v-alert dense text type="success" v-if="dataTrx.status == 0">
                Bukti pembayaran anda sedang diverifikasi oleh Admin
              </v-alert>
              <v-alert dense text type="success" v-if="dataTrx.status == 1">
                Admin sudah memverifikasi bukti pembayaran Anda
              </v-alert>
              <v-alert dense text type="error" v-if="dataTrx.status == 2">
                Transaksi Anda ditolak oleh admin
              </v-alert>
            </div>
          </div>
          <v-divider class="my-2"></v-divider>
          <div
            v-if="
              dataTrx.konsultasi_paket_id == 1 ||
                dataTrx.konsultasi_paket_id == 2
            "
          >
            Atau pakai voucher yang kamu punya
            <div class="d-flex align-center">
              <v-text-field
                dense
                class="mr-2"
                color="#3b053c"
                hide-details
                v-model="vcr"
                :disabled="claiming"
                outlined
              ></v-text-field>
              <v-btn
                v-if="!claiming"
                color="#3b053c"
                @click="redeemVcr"
                dark
                width="100px"
                height="40px"
                depressed
              >
                Redeem
              </v-btn>
              <v-btn
                v-if="claiming"
                color="#3b053c"
                dark
                width="100px"
                height="40px"
                depressed
              >
                <v-progress-circular
                  size="20"
                  indeterminate
                ></v-progress-circular>
              </v-btn>
            </div>
          </div>
          <v-divider class="my-3"></v-divider>
          <v-btn
            outlined
            color="#3b053c"
            to="/public/dashboard-counseling"
            depressed
            block
          >
            Kembali ke menu Konseling
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            outlined
            color="#3b053c"
            to="/psikolog/counseling"
            depressed
            block
          >
            Kembali ke menu Konseling
          </v-btn>
        </div>
        <div class="pa-3">
          <v-snackbar v-model="copied" color="success" :timeout="2000">
            Text copied!
          </v-snackbar>
          <upload-evidence
            :dialogAlert="dialogAlert"
            @close="dialogAlert = false"
            @refetch="fetchData"
          />
        </div>
      </v-card>
      <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="purple"
        ></v-progress-circular>
        <b class="purple--text">Loading...</b>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import uploadEvidence from "./Modal/uploadEvidence.vue";
import "dayjs/locale/id";

export default {
  components: { uploadEvidence },
  name: "trxByid",
  computed: {
    ...mapState({
      env: (state) => state.API_URL,
      dummy: (state) => state.dummy,
      id: (state) => state.id,
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      dateNow: this.$date().format("YYYY-MM-DD"),
      loading: false,
      dialogAlert: false,
      dataTrx: null,
      rek: [],
      vcr: "",
      claiming: false,
      paket: null,
      copied: false,
    };
  },
  mounted() {
    this.fetchData();
    this.getRek();
  },
  methods: {
    copyRek(content) {
      navigator.clipboard.writeText(content);
      this.copied = true;
    },
    fetchData() {
      this.loading = true;
      let data = this.$route.params.id;
      let actions = this.role == 3 ? "trxPsyByd" : "viewTransaction";
      this.$store.dispatch(`counseling/${actions}`, data).then((data) => {
        this.dataTrx = data[0];
        this.getPaket(data[0].konsultasi_paket_id);
        this.loading = false;
      });
    },
    getRek() {
      this.$store.dispatch("counseling/rekber").then((data) => {
        this.rek = data;
      });
    },
    getPaket(id) {
      this.$store.dispatch("counseling/getPackage").then((res) => {
        let paket = res.find((el) => el.id == id);
        this.paket = paket;
      });
    },
    redeemVcr() {
      this.claiming = true;
      let data = {
        kode: this.vcr,
        konsultasi_transaction_id: this.dataTrx.id,
      };
      this.$store
        .dispatch("counseling/claimVcr", data)
        .then(() => {
          this.Swal("success", "Berhasil Klaim Voucher");
          this.claiming = false;
          this.$router.push(`/public/dashboard-counseling`);
        })
        .catch((e) => {
          console.log(e);
          if (e.data) {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          } else {
            this.$toast.error(e.message);
          }
          this.claiming = false;
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.toolbar {
  width: 50%;
  background: #eae8e8;
  border-radius: 10px 0 0 10px !important;
}
.list_psy {
  width: 50%;
}
.another_filter {
  background: #eae8e8;
  padding: 5px;
  width: max-content;
  margin-bottom: -15px;
}
.circle_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3b053c;
}
.litle_ {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}
.pp {
  width: 100px;
}
.dp {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}
.active_time {
  background: #3b053c !important;
  color: #fff !important;
}
</style>
